<template>
  <v-container style="max-width:1200px;">
    <v-row align="start" justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card flat tile v-if="displayUserAfterFilter"
          style="border-radius:8px;" >
          <v-card-title
            class="py-1"
            style="font-size:24px;color:teal;border-bottom:1px solid teal;"
          >
            <v-btn icon color="teal" @click="goBack" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Hồ sơ cá nhân
            <v-spacer />
            <v-btn
              class="my-1 mr-2"
              style="min-width:90px;"
              color="red"
              dark
              @click="callDeleteUser"
            >
              Xóa
            </v-btn>
            <v-btn
              class="my-1"
              style="min-width:90px;"
              color="primary"
              dark
              @click="callUpdateUser"
            >
              Lưu
            </v-btn>
          </v-card-title>
          
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
        <v-card flat style="border-radius:8px;">
          <v-card-text class="px-1 py-1">
            <div
              v-if="displayUserAfterFilter"
              style="width:100%;font-size:15px;"
            >
              <div>
                <div style="width:120px;display:inline-block;">
                  Mã Nhân Viên:
                </div>

                <div style="width:320px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.user_code"
                    :rules="[rules.required]"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>

              </div>
              

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Họ và Tên:
                </div>
                <div style="width:320px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.fullname"
                    :rules="[rules.required, rules.fullname]"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Phòng Ban:
                </div>
                <div style="width:320px;display:inline-block;">
                  <v-select
                    v-model="user.department"
                    :items="departments"
                    item-value="_id"
                    item-text="name"
                    color="#DD0011"
                    :rules="[rules.required]"
                    outlined
                    dense
                    single-line
                    hide-details
                  ></v-select>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Chức vụ:
                </div>
                <div style="width:320px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.position"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Email:
                </div>
                <div style="width:320px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.email"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Số điện thoại:
                </div>
                <div style="width:320px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.mobile"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Nơi làm việc:
                </div>
                <div style="width:320px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.work_at"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Ngày tạo
                </div>
                <div style="width:320px;display:inline-block;">
                  {{
                    $moment(displayUserAfterFilter.createdDate).format(
                      "HH:mm DD-MM-YYYY"
                    )
                  }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
        <v-card flat style="border-radius:8px;">
          <v-card-text class="px-2 py-1">
            <div
              v-if="displayUserAfterFilter"
              style="width:100%;font-size:15px;"
            >
                <div class="">
                  Ảnh đại diện
                </div>

                <div class="pb-1 pt-1">
                  <input
                    type="file"
                    style="display: none"
                    ref="imageInput"
                    accept="*/*"
                    @change="handleFileUpload"
                  />

                  <v-btn
                    @click="startPickImage"
                    class="ml-2"
                    color="teal"
                    outlined
                    style="min-width:20px;width:46px;"
                  >
                    <v-icon>
                      add_photo_alternate
                    </v-icon>
                  </v-btn>
                </div>

                <div>
                  <v-img
                    width="192"
                    height="192"
                    contain
                    style="border:1px solid #DDDDDD;"
                    :src="
                      displayUserAfterFilter.avatar
                        ? displayUserAfterFilter.avatar
                        : '/imgs/user-placehold.jpg'
                    "
                  />
                </div>
              </div>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card flat style="border-radius:8px;">
          <v-card-text class="px-1 py-1" v-if="user">
              <div class="mt-4"
                style="color:teal;font-weight:700;font-size:16px;border-bottom:1px solid teal;">
                Cách thức vào cửa:
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Mật khẩu:
                </div>
                <div style="width:160px;display:inline-block;">
                  <v-text-field
                    v-if="user.zk_access"
                    flat
                    dense
                    v-model="user.zk_access.password"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;">
                  Mã Thẻ:
                </div>
                <div style="width:160px;display:inline-block;">
                  <v-text-field
                    flat
                    dense
                    v-model="user.zk_access.card_no"
                    color="#DD0011"
                    clearable
                    outlined
                    single-line
                    hide-details
                    placeholder=""
                  ></v-text-field>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;vertical-align:top;">
                  Vân tay:
                </div>
                <div style="width:300px;display:inline-block;vertical-align:top;">
                  <div v-for="finger in user.zk_access.fingers"
                    :key="finger.index"
                    style="display:inline-block;">
                    <v-icon  
                      :color="finger.template.length>0?'teal':'#AAAAAA44'"
                      >
                        mdi-fingerprint
                    </v-icon>
                    <div class="text-center" style="font-size:10px;">
                      {{finger.index+1}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div style="width:120px;display:inline-block;vertical-align:top;">
                  FaceId:
                </div>
                <div style="width:300px;display:inline-block;vertical-align:top;">
                  <div v-for="face in user.zk_access.faces"
                    :key="face.index"
                    style="display:inline-block;">
                    <v-icon  
                      :color="face.template.length>0?'teal':'#AAAAAA44'"
                      >
                        mdi-guy-fawkes-mask
                    </v-icon>
                    <div class="text-center" style="font-size:10px;">
                      {{face.index + 1}}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <v-btn color="primary"
                  class="text-none"
                  @click="dialogUser=true">
                  Sao chép vân tay/faceId từ User khác
                </v-btn>
              </div>
              <v-dialog v-model="dialogUser"
                max-width="460px">
                <dialog-select-user 
                  @closeMe="dialogUser=false"
                  @onUserSelected="onOneUserPick"/>
              </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
        class="mt-2"
        v-if="displayUserAfterFilter && displayUserAfterFilter.user_code">
        <div v-if="1==2">
          <v-btn text color="primary"
            class="text-none"
            @click="reuquestReadFullDetailAllDoor">
            Tải thông tin chi tiết từng cửa
          </v-btn>
        </div>

        <template v-if="showAllDoor"> 
          <v-row  v-for="(floor, index) in floors" :key="index" 
            class="mb-6 mt-2"
            style="background-color:F2F2F2;">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
              class="py-1"
              style="color:#01579B;font-size:16px;font-weight:600;border-bottom: 1px solid #01579B;">
              {{floor.name}}
            </v-col>
            <v-col v-for="(door, dIndex) in floor.doors" :key="dIndex"
              cols="6" xs="6" sm="6" md="4" lg="4" xl="4"
              class="py-0 pt-2"
              :class="{
                'px-1': $vuetify.breakpoint.smAndDown,
                'px-2': $vuetify.breakpoint.mdAndUp,
                }"
              >
                <user-door-state
                  :door="door"
                  :user="user"
                  :userId="displayUserAfterFilter.user_code"
                  :zkAccess="displayUserAfterFilter.zk_access" />
            </v-col>
          </v-row>
        </template>


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DialogSelectUser from "./DialogSelectUser.vue";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayUserAfterFilter() {
      let result = null;
      if (!this.user) return null
      result = JSON.parse(JSON.stringify(this.user));
      if(!result.zk_acccess){
        result.zk_acccess = {
          user_id: '',
          password: '',
          card_no: '',
          enable: true,
          fingers: [],
          faces: [],
        }
      }
      return result
    },
  },
  components: { DialogSelectUser },
  data() {
    return {
      roles: ['user-manager', 'admin'],
      user: null,
      departments: [],
      editPwdMode: false,
      socketAskKey: new Date().getTime(),
      showAllDoor: true,
      rules: {
        required: (value) => !!value || "* Required",
        passRule: (v) => (v && v.length >= 6) || "Min 6 chars",
        username: (v) => (v && v.length >= 6) || "Min 6 chars",
        fullname: (v) => (v && v.length >= 3) || "Min 3 chars",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
      floors: require('@/mkdata/floors').floors,
      userHeaders: [
        {
          text: "Mã NV",
          align: "start",
          sortable: true,
          value: "user_code",
          width: 100,
        },
        { text: "Tên", align: "start", sortable: true, value: "fullname" },
      ],
      dialogUser: false,
    }
  },
  sockets: {
      'setUserInfo-REPLY': function (payload) {
         this.loading = false
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            console.log('setUserInfo-REPLY')
            console.log(this.result)
         }
      }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    startPickImage() {
      this.$refs.imageInput.click();
    },
    handleFileUpload: function() {
      var file = this.$refs.imageInput.files[0];
      if (file !== undefined) {
        var self = this;
        self.result = "";
        let formData = new FormData();
        formData.append("file", file);
        self.axios
          .post("https://file.ponotek.com/uploads", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(res) {
            self.user.avatar = res.data.content;
          })
          .catch(function(err) {
            console.log(err);
          });
      }
    },
    callDeleteUser() {
      let self = this;
      this.axios
        .put(
          this.$root.apiUser + "/users/delete/" + self.user._id,
          {},
          {
            headers: {
              "x-auth": this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            alert("Xóa thành công thành công!");
            window.location = '/users'
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    callUpdateUser() {
      if (!this.user) return;
      if (!this.user.user_code || this.user.user_code.length <= 0) {
        alert("Mã nhân viên không được để trống!");
        return;
      }
      if (!this.user.fullname || this.user.fullname.length <= 0) {
        alert("Họ và tên không được để trống!");
        return;
      }
      if (!this.user.department || this.user.department.length <= 0) {
        alert("Phòng ban không được để trống!");
        return;
      }
      if (!this.user.mobile != true && this.user.mobile.length < 8) {
        alert("Số điện thoại quá ngắn!");
        return;
      }

      let userId = this.user.user_code.replace("NV","")
      if(!userId.includes("T")){
        userId = "" + Number(userId)
      }

      this.requestDeviceToUpdateNewInfo(
          userId, 
          this.user.fullname,
          this.user.zk_access.password,
          this.user.zk_access.card_no)

      let self = this;
      this.axios
        .put(
          this.$root.apiUser + "/users/update/" + self.user._id,
          {
            user_code: this.user.user_code,
            fullname: this.user.fullname,
            department: this.user.department,
            position: this.user.position,
            work_at: this.user.work_at,
            email: this.user.email,
            mobile: this.user.mobile,
            avatar: this.user.avatar,
            zk_acccess: this.user.zk_acccess,
          },
          {
            headers: {
              "x-auth": this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            self.user = response.data.content;
            alert("Cập nhật thành công!");
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    processAfterFetchDataUser() {
      if (this.user) {
        //Departments
        if (!this.departments.find((i) => i._id == this.user.department)) {
          this.user.department_name = "-";
        } else {
          this.user.department_name = this.departments.find(
            (i) => i._id == this.user.department
          ).name;
        }
      }
    },
    fetchUser() {
      let self = this;
      let pathFetchDataUser =
        self.$root.apiUser + "/users/get/" + this.$route.params.user_id;
      let pathFetchDataDepartments =
        self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId;

      Promise.all([
        self.axios.get(pathFetchDataUser),
        self.axios.get(pathFetchDataDepartments),
      ])
        .then((responseArray) => {
          console.log("Promise ALL", responseArray);
          if (responseArray[0].data.status == "OK") {
            self.user = responseArray[0].data.content;
            console.log(self.user)
          } else {
            alert(responseArray[0].data.message)
          }
          if (responseArray[1].data.status == "OK") {
            self.departments = responseArray[1].data.content.items;
          } else {
            alert(responseArray[1].data.message)
          }
          self.processAfterFetchDataUser()
          self.fetchUsers()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    requestDeviceToUpdateNewInfo(userId, name, password, card_no ){
      this.floors.forEach(floor => {
        floor.ips.forEach(ip => {
          // console.log("IO Send requestUpdateUserInfo")
          let payload = {
            command: "requestUpdateUserInfo",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + ip,
            paras: {
                userId: userId,
                name: name || "",
                card_no: card_no || "",
                password: password || ""
            }
          }
          // console.log(payload)
          this.$socket.emit('sendMagicCommand', payload)
        })
      })
    },
    reuquestReadFullDetailAllDoor () {
      this.showAllDoor = true
      this.$emit('requestChildReadUserFull')
      // let self = this
      // setTimeout(function(){
      //   self.$emit('requestChildReadUserFull')
      // }, 500)
    },
    onOneUserPick (pickedUser) {
      this.dialogUser = false
      console.log(pickedUser)
      if(confirm('Xác nhận copy thông tin vân tay/faceid từ user ' + pickedUser.fullname + ' vào user ' + this.user.fullname + '?')) {
        this.fetchUserById(pickedUser._id).then(user => {
          this.user.zk_access = user.zk_acccess
          console.log(user)
        }).catch(console.log)
        // alert('Copy xong!')
        this.$forceUpdate()
      }
    },
    fetchUserById(userId) {
      return new Promise((resolve, reject) => {
        let self = this;
        let pathFetchDataUser =
          self.$root.apiUser + "/users/get/" + userId
        this.axios.get(pathFetchDataUser).then(res => {
          if (res.data.status == "OK") {
              resolve(res.data.content)
            }
          else { reject(res.data.status)}
        }).catch(reject)
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUser()
    });
  },
};
</script>

<style></style>
